<template>
  <div class="migration-wrapper">
    <link
      href="https://fonts.googleapis.com/css2?family=Baskervville:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />

    <div class="gradient-container">
      <div class="gradient-color"></div>
      <div class="gradient-color"></div>
      <div class="gradient-color"></div>
      <div class="gradient-color"></div>
      <div class="gradient-backdrop"></div>
    </div>
    <div class="success-form show">
      <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
      <div class="success-info-wrapper">
        <h3>You've been updated to <i>our new version</i></h3>
        <p>
          We are rolling out our new version in phases.<br />Your login has been
          temporarily moved and you'll have to login with the same credentials
          <a href="https://staging.getmacha.com/sign-in"> here</a>, again.
        </p>
        <div class="success-btn-wrapper">
          <a
            href="https://staging.getmacha.com/sign-in"
            class="btn btn-primary btn-small"
          >
            Login Here
          </a>
        </div>
        <br />
        <p>This is temporary and we're sorry for the inconvenience</p>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Migration",
  props: {},
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>
  
<style scoped lang="scss">
$gradient-color-1: #14131e;
$gradient-color-2: #552888;
$gradient-color-3: #181620;
$gradient-color-4: #14121a;
$blur: blur(10vw);

.migration-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: #000;

  &::before {
    content: "";
    background-image: url(https://getmacha.b-cdn.net/img/noise.b3a36bb8.webp);
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.85;
    z-index: 1;
  }
}

.gradient-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  display: flex;
  background: $gradient-color-2;
  flex-wrap: wrap;
  overflow: hidden;

  .gradient-color {
    width: 50%;
    height: 50%;
    display: flex;
    border-radius: 30% 80% 75% 40% / 40% 40% 70% 50%;

    &:first-child {
      background-color: $gradient-color-1;
    }

    &:nth-child(2) {
      background-color: $gradient-color-2;
    }

    &:nth-child(3) {
      background-color: $gradient-color-3;
    }

    &:nth-child(4) {
      background-color: $gradient-color-4;
      z-index: 1;
      filter: blur(100px);
      transform: translateZ(0);
    }
  }

  .gradient-backdrop {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    backdrop-filter: $blur;
    -webkit-backdrop-filter: $blur;
  }
}

.logo {
  display: block;
  width: 10rem;
  margin: 0 auto 3rem;
  filter: invert(1);
}

.success-form {
  display: none;
  flex-direction: row;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40rem;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.success-form.show {
  display: block;
}

.success-form .unicon /deep/ svg {
  display: block;
  align-items: center;
  fill: darken($greenColor, 30%);
  stroke: darken($greenColor, 30%);
  background-color: lighten($greenColor, 15%);
  border: 10px solid lighten($greenColor, 25%);
  padding: 0.75rem;
  width: 2rem;
  height: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
  path {
    stroke-width: 2;
  }
}

.success-info-wrapper {
  h3 {
    font-size: 2rem;
    font-family: Baskervville;
    color: $whiteColor;
  }

  h3 + p {
    font-size: 1rem;
  }
  p {
    font-size: $smallFontSize;
    color: #958bb0;
  }
}

.success-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 .5rem;
  .btn {
    font-size: 1rem;
    background: $purpleColor;
    border-radius: 3rem;
    margin-right: 0.75rem;
  }
}
</style>